html, body { 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.login-box-msg {
    margin-bottom: 57px;
    text-align: center;
    margin-top: -20px;
}

.success-responsive{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    outline: none; 
    font-weight: normal;
    font-size: 20px;
    line-height: 33px;
    padding: 5px; 
    padding-left: 20px;
    color: #21252B;
    background: #FFFFFF; 
    border: 1px solid #FFFFFF; 
    box-sizing: border-box;
    border-radius: 47px;
    width: 140px;
}

.navbar-items{
    display: flex;
    justify-content: center;
    align-items: center;
}

.titre{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 97px;
    color: #FFFFFF;
}

.input-group-addon:first-child {
    padding-bottom: 5px;
}

.form{
    width: 330px;
    height: 390px;
    background: rgba(0, 135, 177, 0.72);
    border-radius: 49px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.form-prem{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.modal-vertical-centered {
    transform: translate(0, 25%) !important;
    -ms-transform: translate(0, 25%) !important; /* IE 9 */
    -webkit-transform: translate(0, 25%) !important; /* Safari and Chrome */
  }

.Logo-self-align{
    margin-top: 20px;
    margin-bottom: 20px;
}

.b-input{
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 52px;
}

#contact-phone {
    margin-right: 4.5rem;
    margin-left: 13px;
}

.contact-mail{
    margin-right: .5rem;
}

#mentions{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 46px;
    color: #FFFFFF;
    position: absolute;
    padding: 30px;
    bottom: 0;
    left:0;
    margin-left: 500px;  
}

#mentions:hover{
    cursor: pointer;
}

#contact:hover{
    cursor: pointer;
}

.titre-contact{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
     font-style: normal;
     font-weight: normal;
     font-size: 40px;
     color: #0087b1;
     line-height: 80px;
}

#contact{
    position: absolute;
    padding: 30px;
    bottom: 0;
    right:0;
    margin-right: 500px; 
}

.row{
    margin-right: 0;
}

.first-navbar{
    background-color: rgba(52, 52, 52, 0.01);
    border: 0;
    height: 100px;
    margin-top: 10px;
}

.leaflet-top {
    bottom: 0 !important;
    padding-bottom: 15px;
    top: initial;
}

.modal-contact-desktop{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400px;
    font-size: 22px;
    color: #0087b1;
    
    margin-top: 31px;
    margin-bottom: 30px;
    line-height: 1px;
}

.modal-header {
    padding: 6px;
    padding-bottom: 0px;
}

.modal-header .close {
    margin-top: 0px;
    margin-right: 4px;
}

button.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
}

.close {
    float: right;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 1.2;
    outline: none; 
}

.close::after{
    background-image: url('/images/logo/close.svg');
    background-size: 40%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 45px; 
    height: 28px;
    margin-right: -27px;
    content:"";
}

.close:hover{
    color: #FFFFFF;
    opacity: 1.2;
}

.modal-tel-desktop{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px; 
    color: #676767;
    margin-bottom:-7px;
    line-height: 14px;
}

.modal-close-button-desktop{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    width: 88px;
    text-align: center;
    font-style: normal;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
    border-radius: 54px;
    background-color: #0087b1;
    line-height: 30px;
    padding: 2px;
    outline: none;
}

.modal-close-button-desktop:hover{
    cursor: pointer;
    background-color: #0087b1;
}

.modal-credit-desktop{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px; 
    color: #676767;
    line-height: 10px
}


.toogle-wind{
    padding-top: 4px;
}

.toogle-lost-connection{
    padding-top: 9px;
}

@media (max-width: 1350px) {

    #mentions{
        margin-left: 0; 
    }

    #contact{
        margin-right: 0; 
    }
}

@media (max-width: 1200px){
    .map-style{
        position: initial;
        top:initial;
     }
     .powerplant-tracker {
        margin-top: 4px;
        margin-bottom: 4px;
    }
    .wind-location {
        display: flex !important;
        justify-content: space-around;
    }
    .wind-location2 {
        display: flex !important;
        justify-content: space-around;
    }
    .lost-remote-connection-true{
        display: block !important;
    }
    .message-position {
        position: unset;
        width: 52%;
        position: absolute;
    }

    .add-margin{
        margin-top: -1px;
        margin-bottom: 8px;
    }
    .tracker-badge-error {
        margin-right: -19px;
    }
    
}

@media (max-width: 991px){
    .map-style{
        position: initial;
        top:initial;
     }
     .powerplant-tracker {
        margin-top: 4px;
        margin-bottom: 4px;
        justify-content: space-evenly;
    }
    .progress-bar-striped, .progress-striped .progress-bar {
        margin-right: 46vw !important;
        margin-left: 42px;
    }
    .wind-location {
        display: flex !important;
        justify-content: space-around;
    }
    .wind-location2 {
        display: flex !important;
        justify-content: space-around;
    }
    .lost-remote-connection-true{
        display: block !important;
    }
    .message-position {
        position: unset;
        width: 94%;
        position: absolute;
    }

    .add-margin{
        margin-top: -1px;
        margin-bottom: 8px;
    }
    .tracker-badge-error {
        margin-right: -19px;
    }
    
}

@media (max-width:870px) {

    ._ju7HT {
        height: 80%;
    }
    
    ._3QxTi {
        width: 80%;
    }

    
    ._ju7HT._3YMB2 {
        transform: translateX(12px) !important;
    }

    .logo-responsive{
        width: 15px;
        height: 15px;
    }

    .success-responsive{
        width: 6rem;
        height: 3rem;
        font-size: 1rem !important;
        margin-top: -10px;
        margin-left: 1rem;
    }

    .logo-optimum-tracker{
        width: 9rem;
        height: 2rem;
    }

    .Title-Optimum-Tracker{
        font-size: 1.2rem !important;
        padding-top: 1px !important;
    }
    .leaflet-top {
        padding-top: 20px;
        top:0;
        bottom:initial;
    }
    .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY{
        display: none;
    }

    .header-title{
        display: none;
    }

    input#search {
        background-size: contain;
        text-indent: 20px;
        background-position: 11px 1px;
    }

    .toogle-wind{
        padding-top: initial;
    }

    .toogle-lost-connection{
        padding-top: initial;
    }
    
}

 @media (max-width: 767px){
    #contact-phone {
        margin-right: .5rem;
        margin-left: initial;
    }

    .tel-contact-position{
        text-align: center;
    }
 }

@media (max-width: 550px) {

    .titre{
        font-size: 20px;
        line-height: 97px;
        margin-top: -30px;
       
    }

    .logo-tracker{
        margin-top: 5px;
        width: 130px;
        height: 55px;
    }
    
    .form{
        width: 240px;
        height: 330px;
    }
    
    
    .form-prem{
        margin-top: -10px;
    }
    
    .Logo-self-align{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .b-input{
        border: 3px solid #FFFFFF;
        box-sizing: border-box;
    }
    .input-group {
        width: 190px;
    }

    .container{
        width: 150px;
    }
    .login-box-msg {
        font-size: 9px;
    }
}

@media (max-width: 360px){
    .logo-responsive{
        width: 1rem;
        height: 1rem;
    }
}

@media (max-width: 280px){
    .logo-responsive{
        width: 1rem;
        height: 1rem;
    }

    .success-responsive{
        width: 4rem;
        height: 2rem;
        font-size: 0.5rem !important;
        margin-top: -10px;
        margin-left: 1rem;
    }

    .logo-optimum-tracker{
        width: 9rem;
        height: 2rem;
    }

    .title-optimum-tracker {
        font-size: 0.7rem !important;
        padding-top: 1px !important;
        border: 1px ​solid rgb(77, 97, 252) !important;
    }
}


@media (min-width: 768px){
    .modal-sm {
        width: 367px;
    }
}
@media (max-width: 332px){
    .font-sorting-bar-text {
        font-size: 0.7rem;
    }
    .Dropdown-option {
        padding: 0rem 1rem !important;
    }
}

@media (max-height: 750px) {
    #mentions, h4{
        font-size: 12px;
    }

    .contact{
        width: 30px;
        height: 30px;
    }
}

@media (min-width: 1201px) and (max-width: 1283px){
    .logo-responsive{
        width: 1rem;
        height: 1rem;
    }
    .font-sorting-bar-text{
        font-size: 1rem;
    }
    .name {
        font-size: 1rem;
    }
    .message-power-position {
        font-size: 1rem;
        width: 11%;
        max-width: unset;
    }
    .wind-text-logo{
        margin-top: 6px;
    }
    .font-sorting-bar-text {
        line-height: 13px;
    }
}

@media (min-width: 992px) and (max-width: 1200px){
    .logo-responsive{
        width: 2rem;
        height: 2rem;
    }
    .font-sorting-bar-text{
        font-size: 14px;
    }
    .name {
        font-size: 14px;
    }
    .wind-text-logo{
        margin-top: 6px;
    }
    .font-sorting-bar-text {
        line-height: 13px;
    }
}

@media (min-width: 992px) and (max-width: 1200px){
    div.progress {
        /*width: 75%;*/
        /*width: 40%;*/
    }
    .trackers-on {
        width: 20%;
    }

    .message-position {
        position: unset;
        width: 19%;
        position: absolute;
    }

    .message-power-position {
        font-size: 12px;
        width: 15%;
        max-width: unset;
    }
}

@media (min-width: 992px) and (max-width: 1621px){
    .tracker-text-logo{
        padding-left: 0px;
    }
}

@media (min-width: 992px) and (max-width: 1200px){
    .powerplant-tracker {
        margin-top: -1px;
    }
}

@media (max-width: 1388px){
    .another-alignement{
        display: inline-flex;
        padding-bottom: 7px;
    }
}

@media (min-width: 992px) and (max-width: 1170px){
    .col-md-3 {
        width: 10%;
    }
    .power-position {
        margin-left: 24px;
        margin-right: -1px;
        width: 35%;
    }
}

@media (min-width: 650px) and (max-width: 991px){
    .progress-bar-striped, .progress-striped .progress-bar {
        margin-left: 81px;
    }
}

@media (min-width: 2200px) and (max-width: 2500px){
    .message-position {
        width: 18%;
    }
}

@media (min-width: 2501px) and (max-width: 2600px){
    .message-position {
        width: 20%;
    }
}

@media (min-width: 2601px) and (max-width: 3000px){
    .message-position {
        width: 22%;
    }
}

@media (min-width: 3001px) and (max-width: 3299px){
    .message-position {
        width: 23%;
    }
}

@media (min-width: 3300px) and (max-width: 3500px){
    .message-position {
        width: 27%;
    }
}

@media (min-width: 3501px) and (max-width: 3799px){
    .message-position {
        width: 28%;
    }
}

@media (min-width: 3800px) and (max-width: 4000px){
    .message-position {
        width: 29%;
    }
}

@media (min-width: 4001px){
    .message-position {
        width: 32%;
    }
}