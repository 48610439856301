/* ---------- */
/* - Layout - */
/* ---------- */
html {
    height: auto;
    min-height: 100%;
}

body {
    position:absolute;
    top:0px;
    bottom:0;
    right:0;
    left:0;
    background-color: rgb(34, 45, 50);
}

.body {
	padding-top: 51px;
}

div.progress {
	margin-bottom: 0px;
}

div.wind {
	padding-right: 0px;
}

/* --------------- */
/* - Spinner tip - */
/* --------------- */

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

*{
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;;
}
