.name{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
	/*display: ruby;*/
    line-height: 20px;
    color: #23527c;
	/*display: block;*/
    line-height: 20px;
    color: #23527c;
    /*width: 300%;*/
	padding-left: 20px;
	white-space: nowrap;
}

.name:hover{
	color: #23527c;
}

.name-padding{
	padding-left: 15px;
}

.powerplant-name{
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
	/*display: ruby;*/
    line-height: 20px;
    color: #23527c;
	display: block;
    line-height: 20px;
    color: #23527c;
    width: 300%;
	padding: 0;
	padding-right: -5px;
	margin: 0;
}

.powerplant-name:hover{
	color: #23527c;
}

.location{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: -10px;
    padding-right: 15px;
    padding-bottom: 15px;
}


.marker-btn {
	background: none;
	border: none;
	cursor: pointer;
}
  
.marker-btn img {
	width: 20px;
	height: 20px;
}

.powerplant-power{
	text-align: center; 
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif; 
	font-style: normal; 
	font-weight: normal; 
	font-size: 17px;
	color: #000000;
	line-height: 20px;
}

.powerplant-wind{
	vertical-align: top; 
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif; 
	font-style: normal; 
	font-weight: normal; 
	font-size: 11px;
	color: #23527c; 
	line-height: 20px;
	white-space: nowrap;
	/*padding-left: 0px;*/
}

.powerplant-tracker{
	text-align: center; 
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif; 
	font-style: normal; 
	font-weight: normal; 
	font-size: 15px;
	color: #000000;
	line-height: 20px;
	display: flex;
    justify-content: space-between;
}

.progress-bar {
    color: #333;
}

.wind-location{
	display: inline-flex;
	margin-left: 1%;
}

.wind-location2{
	display: inline-flex;
	/*margin-left: 20px;*/
	margin-left: 2.4%;
}

.central-items{
	display: flex;
	justify-content: space-evenly;

}

.power-progress-bar{
	font-size: 12px;
	/*padding-left: 5px;*/
	/*background: #4D61FC;*/
	color: transparent;
	background: #0087b1;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 25px;
}

.no-power-progress-bar{
	color: transparent;
	border-radius: 25px;
	background:linear-gradient(0deg, #B2B6BB, #B2B6BB), linear-gradient(0deg, #B2B6BB, #B2B6BB), #B2B6BB;
}

.tracker-progress-bar{
	background-color: #66C872;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 25px;;
	color: #FFFFFF;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;;
	font-size: 12px;
	height: 21px;
}

.another-tracker-progess-bar{
	background-color: #66C872;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 25px;;
	color: #FFFFFF;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;;
	font-size: 12px;
	height: 21px;
	width: 100 !important;
}

.tracker-badge-manual{
	color: #FFFFFF;
	background: #FDB62F;
	font-size: 15px;
	border-radius: 10px;
	display: inline-block;
	/*font-weight: 700;*/
	line-height: 1;
	min-width: 10px;
	padding: 3px 7px;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
	/*margin-left: -2px;*/
	margin-left: 8px;
	margin-top: -1px;
}

.remove-margin{
	margin-top: -3px;
}

.tracker-badge-error{
	color: #FFFFFF;
	background: #E01818;
	font-size: 15px;
	border-radius: 10px;
	display: inline-block;
	/*font-weight: 700;*/
	line-height: 1;
	min-width: 10px;
	padding: 3px 7px;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
	margin-left: 7px;
	/*margin-right: 17/12px;*/
	/*margin-right: -19px;*/
	margin-top: -1px;
}

.lost-remote-connection-true{
	color: #777;
	cursor: not-allowed;
	background: #eee;
	border: 1px solid #eee;
	box-sizing: border-box;
	border-radius: 27px;
	padding-top: 11px; 
	padding-bottom: 6px; 
	padding-left: 2%;
	width: 99%; 
	margin-left: 0%;
	display: flex !important;
	margin-bottom: 4px;
}

.not-message{
	display: none;
}

.message-position{
	font-size: 15px;
	font-weight: bold;
	color: #FFFFFF;
	position: absolute;
	/*width: 20%;*/
	width: 16%;
	z-index: 1;
}

.message-power-position{
	font-size: 14px;
	color: #FFFFFF;
	position: absolute;
	/*width: 88%;*/
	width: 13.5%;
	z-index: 1;
}

.lost-remote-connection-false{
	color: #777;
	background: #FFFFFF;
	border: 1px solid #23527c;
	box-sizing: border-box;
	border-radius: 27px;
	padding-top: 11px; 
	padding-bottom: 4px; 
	padding-left: 2%; 
	width: 99%; 
	margin-left: 0%; 
	margin-bottom: 4px;
}

.power-position{
	margin-left: 24px;
    margin-right: -1px;
	width: 21%;
}

.powerplant-style-screen-mode{
	display: block;
}

.lost-remote-conn-margin{
	margin-left: 5px;
}

.wind-safety-margin{
	margin-top: -1px;
}

@media (min-width: 1201px) and (max-width: 1376px){
	.tracker-badge-manual{
		font-size: 10px;
	}

	.tracker-badge-error{
		font-size: 10px;
	}

	.message-position{
		font-size: 12px;
		width: 11%;
    	margin-top: 2px;
	}

	.message-power-position {
		font-size: 12px;
	}

	.name {
		font-size: 13px;
	}

	table tr:nth-child(n) td:nth-child(n) {
		font-size: 13px;
	}
}


@media (min-width: 1376px) and (max-width: 1425px){
	.message-position {
		font-size: 12px;
		width: 12%;
   		margin-top: 2px;
	}
	.tracker-badge-error{
		font-weight: initial;
	}
	.tracker-badge-manual{
		font-weight: initial;
	}
}

@media (min-width: 1426px) and (max-width: 1600px){
	.message-position {
		font-size: 12px;
		width: 13%;
   		margin-top: 2px;
	}
	.tracker-badge-error{
		font-weight: initial;
	}
	.tracker-badge-manual{
		font-weight: initial;
	}
}

@media (min-width: 1601px) and (max-width: 1750px){
	.message-position {
		width: 15%;
	}
}

@media (min-width: 992px) and (max-width: 1200px){
	.tracker-badge-manual{
		font-size: 14px;
	}

	.tracker-badge-error{
		font-size: 14px;
		margin-left: -42px;
	}

	.message-position{
		font-size: 14px;
	}
}

@media (min-width: 1200px) and (max-width: 1600px){
	.logo-connection-responsive{
		width: 1rem;
		height: 1rem;
	}
}

@media (max-width: 991px){
	#popover-zoom-location{
		display: none !important;
	}
	
}
.leaflet-container {
	height: 100%;
	width: 100%;
	margin: 0 auto;
}

.leaflet-map{
	z-index: 100000;
	border: 8px solid #FFFFFF;
	box-sizing: border-box;
	border-radius: 35px;
	padding: 10px;
}

.leaflet-map-mobile{
	border: 8px solid #FFFFFF;
	box-sizing: border-box;
	border-radius: unset;
	padding: 10px;
}

.toogle-map{
	z-index: 100000; 
	position: relative; 
	display: flex; 
	justify-content: flex-end; 
	align-items: flex-start; 
	padding-top: 15px; 
	padding-right: 15px;
}

.body-style{
	background: #0E202D;
	/*height: 120vh;*/
	padding-bottom: 3%;

}

[href="https://leafletjs.com"]{
	font-size: 11px;
}

[href="https://www.openstreetmap.org/copyright"]{
	font-size: 11px;
	margin-right: 5px;
}

[href="https://www.mapbox.com/"]{
	font-size: 11px;
	margin-right: 40px;
}

.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
    background: rgb(255, 255, 255) none repeat scroll 0% 0% !important;
}

.copyright{
	color: #FFFFFF;
	font-weight: bold;
	font-size: 15px;
	position: relative;
	bottom: 0;
	left: 0;
	padding-left: 50px;
}

.location-image-wind-speed{
	margin-left: 12.5%;
}

.border-sorting{
	padding-left: 0;
}
.search-bar{
    display: flex;
    justify-content: space-between;
}
.search-width{
    width: 160px;
}

.location-image{
    margin-left: 19%;
    /*margin-left: 4%;*/
}

.location-img-logo{
    cursor: pointer;
}

.col-md-9 {
    padding-right: 48px;
    padding-left: 0;
}

.active-menu{
    background-color: #1D7E88;
}

.gx-layout-sider-scrollbar div:first-child{
    overflow-x: hidden !important;
}

#power{
    display: inline-flex;
}

.sorting-dropdown{
    display: none;
}

.style-navbar{
   background-color: rgba(52, 52, 52, 0);
   border:0;
   padding: 0;
   margin: 0;
}

.container-fluid {
    padding-right: unset;
    padding-left: unset;
    margin-right: unset;
    margin-left: unset;
}

.title-optimum-tracker{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style:normal;
    font-weight: normal; 
    font-size: 20px;
    line-height: 33px; 
    padding-top: 7px;
    padding-left: 23px;
    padding-right: 24px;
    background: transparent;
    border: 2px solid #FFFFFF;
    border-radius: 54px;
    color: #FFFFFF;
    margin-right: 15px;
    padding-bottom: 5px;
}

.header-title{
    color: #FFFFFF;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style:normal;
    font-weight: normal; 
}

.header-logo{
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 15px;
}

.logo-optimum-tracker-power{
    margin-right: 10px;
    margin-top: -5px;
    width: 36px;
    height: 36px;
}

.ReactModal__Overlay{
    z-index:-1 !important;
}

.map-style{
   background-color: rgb(14 32 45);
   position: sticky;
   top:10px;
}

.wind{
    margin-right: .5rem;
}

.wind2-safety{
    margin-right: 0rem;
}

input#search {
    background-image: url('/images/logo/search-noire.png');
    background-size: 21%;
    background-repeat: no-repeat;
    text-indent: 20px;
    background-position: 11px 6px;
}

input#search:focus {
    background-image:none;
    text-indent:0px
}

.logo-filter{
    margin-top: 27px;
    margin-left: -500%;
}

#basic-nav-dropdown{
    margin-top: 12px;
}

#basic-nav-dropdown:before {
    background-image: url('/images/logo/filter-white.png');
    background-size: 104%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 22px; 
    height: 22px;
    content:"";
}


#basic-nav-dropdown-active:before {
    background-image: url('/images/logo/filter-active.png');
    background-size: 104%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 22px; 
    height: 22px;
    content:"";
}

.caret{
     display: none;
}

.Dropdown-root{
    display: none;
}

#basic-nav-dropdown::after{
    background-image: url('/images/logo/btn-dropdown.png');
    background-size: 40%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 22px; 
    height: 13px;
    content:"";
}

#basic-nav-dropdown-active::after{
    background-image: url('/images/logo/filter-active-caret.png');
    background-size: 40%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 22px; 
    height: 13px;
    content:"";
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
    color: #555;
    background-color: transparent;
}

.dropdown-menu .divider {
    background-color: #0087B1;
}

.dropdown-menu {
    border: 1px solid rgb(29, 126, 136);
}

.font-filters-name{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    color: #000000;
}

.font-filters-name:hover{
    text-decoration: none;
    color: #FFFFFF;
}

.dropdown-menu>li>a {
    outline: none;
}

.dropdown-menu>li>a:hover{
    background-color: #0087B1;
}

.dropdown-menu>li>a{
    padding-top: 5px;
    padding-bottom: 5px;
}

.lost-connection{
    margin-left: -1px;
    margin-right: 3px;
}

.navbar-nav > li > .dropdown-menu {
    margin-top: -14px;
}

.dropdown-menu{
    padding: 0px 0;
}

.dropdown-menu .divider {
    margin: 0px 0;  
}

/*sorting-bar central items*/
.sorting-bar{
    display: flex;
    justify-content: space-evenly;
    background: #23527c;
    box-sizing: border-box;
    border-radius: 27px;
    margin-bottom: 5px;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 4%;
    padding-right: 0%;
    width: 99%;
}

.sorting-bar-bg{
    /*background: #23527c;*/
    box-sizing: border-box;
    border-radius: 27px;
    position: sticky;
    z-index: 100;
    top: 0;
}

.border-sorting-bar{
    border-bottom: unset !important;
}

table thead tr:first-child th:first-child {
    border-bottom-left-radius: 27px;
    border-top-left-radius: 27px;
}

table thead tr:last-child th:last-child {
    border-bottom-right-radius: 27px;
    border-top-right-radius: 27px;
}

table thead tr th {
    background-color: #23527c;
}

table tr:nth-child(n) td:first-child {
    border-bottom-left-radius: 27px;
    border-top-left-radius: 27px;
}

table tr:nth-child(n) td:last-child {
    border-bottom-right-radius: 27px;
    border-top-right-radius: 27px;
}

table tr:nth-child(n) td:nth-child(n) {
    border-top: unset !important;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: #23527c;
     /*display: block;*/
    /*width: 300%;*/
    white-space: nowrap;
    margin-bottom: 10px;
    background: #FFFFFF;
}

.lost-remote-connection-true-bg{
    color: #777 !important;
    cursor: not-allowed;
    background: #eee !important;
    border: 1px solid #eee !important;
}

table {
    border-collapse: separate;  
    /*border-spacing: 0px 9px;*/
    border-spacing: 0px 4px;
}

.table-sorting-name{
    padding-left: 28px !important;
}

/*table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}*/

/*sorting-bar central items*/
.font-sorting-bar-text{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 23px;
    color: #FFFFFF;
}

.font-sorting-bar-text:hover{
    text-decoration: none;
    color: #FFFFFF;
    cursor: pointer;
}

.font-sorting-bar-text-blue{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    font-size: 11px;
    line-height: 15px;
    color: #000000;
    margin-right: 5px;
}

.font-sorting-bar-text-blue:hover{
    text-decoration: none;
    color: #FFFFFF;
    cursor: pointer;
}

.wind-text-logo{
    display: inline-flex;
}

.text-trackers::after{
    background-image: url('/images/logo/boutton-dropdown-white.png');
    background-size: 28%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 22px; 
    height: 5px;
    margin-left: 7px;
    content:"";
}

#dropdown-no-caret{
    background-color: transparent;
    border: 0;
    padding: 0;
    color: #FFFFFF;
    outline: none;
    box-shadow: none;
}

#dropdown-no-caret:hover{
    background-color: transparent;
    border-color: transparent;
    color: #FFFFFF;
}

.dropdown-menu{
    border-radius: 0;
}

.sorting-bar-hover:hover{
    cursor: pointer;
}

[aria-labelledby="dropdown-no-caret"]{
    left: -74px;
}

.navbar-default .navbar-toggle {
    border-color: transparent;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #3e8cc7;
}

.all-item-elements{
    display: inline-flex;
}

.pinwheel{
    /*margin-left: -11%;*/
    margin-left: -30%;
}

@media (min-width: 768px){
    .navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand {
        margin-left: 15px;
    }

}

@media (min-width: 992px){
    .sorting-bar{
        display: flex;
        justify-content: space-around;
    }
}

@media (max-width: 991px){
    .navbar-nav {
        margin: 7.5px -15px;
        display: flex;
        justify-content: center;
    }

    .nav {
        padding-left: 0;
        list-style: none;
    }

    #basic-nav-dropdown{
        margin-top: 0px;
    }

    .navbar-nav .open .dropdown-menu{
        position: unset;
        background-color: #FFFFFF;
        border: 1px solid rgb(29, 126, 136);
        margin-bottom: 14px !important;
        margin-top: -14px;
        margin-left: -56px;
    }
    .dropdown-menu > li > a {
        color: #000000;
        background-color: none;
    }

    #power{
        display: none !important;
    }

    .Dropdown-root{
        display: block;
        position: relative;
        margin-top: 73px;
        text-align: center;
        display: flex;
        justify-content: center; 
    }

    .Dropdown-menu {
        width: 40% !important;
    }
    .Dropdown-arrow {
        border-color: #FFFFFF transparent transparent !important;
    }
    .is-open .Dropdown-arrow {
        border-color: transparent transparent #FFFFFF !important;
    }
    .Dropdown-control {
        background-color: #23527c !important;
        color: #FFFFFF !important;
    }
    .Dropdown-menu {
        background-color: #23527c !important;
        text-align: left !important;
    }
    .Dropdown-option {
        color: #FFFFFF !important;
        padding: 0.4rem 1rem !important;
    }
    .Dropdown-option.is-selected {
        background-color: #23527c !important;
        color: #FFFFFF !important;
    }
    .Dropdown-option:hover {
        background-color: #0087B1 !important;
        color: #333 !important;
    }
    .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
        background-color: transparent !important;
    }
    .sorting-bar{
        display: none;
    }
    .sorting-dropdown{
        display: block;
    }
    .font-sorting-bar-text {
        font-size: 11px;
    }
}

@media (max-width: 460px){
    .central-items{
        flex-direction: column;
    }
}

@media (min-width: 768px) and (max-width: 870px){
    .navbar-nav {
        margin: -0.5px -15px;
    }
}

@media (min-width: 461px) and (max-width: 540px){
    .location-logo-responsive{
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 10%;
    }
    .location-image {
        margin-left: 5%;
    }
    .windsafety-logo-responsive{
        margin-top: 3px;
        width: 1rem;
        height: 1rem;
    }
    .powerplant-wind {
        margin-top: -1px;
    }
    .central-items{
        padding-left: 30px !important;
        padding-right: 5px;
    }
}

@media (max-width: 382px){
    .font-sorting-bar-text {
        font-size: 1rem;
    }
    .Dropdown-option {
        padding: 0.3rem 1rem !important;
    }
}


@media (min-width: 768px) and (max-width: 870px){
    #basic-nav-dropdown {
        margin-top: 0;
    }
    #power {
        margin-top: -9px;
    }
}

@media (min-width: 992px) and (max-width: 1330px){
    .location-logo-responsive{
        width: 22px;
        height: 22px;
        margin-right: 10%;
    }
    .location-image {
        margin-left: 18%;
    }
    .windsafety-logo-responsive{
        margin-top: 3px;
        width: 15px;
        height: 15px;
    }
    .powerplant-wind {
        margin-top: -1px;
        font-size: 5px;
        font-weight: bold;
    }
}

@media (min-width: 1145px) and (max-width: 1283px){
    .powerplant-wind {
        font-size: 7px;
    }
}

@media (min-width: 1284px) and (max-width: 1330px){
    .powerplant-wind {
        font-size: 7px;
    }
}
@media (min-width: 1331px) and (max-width: 1545px){
    .powerplant-wind {
        font-size: 7px;
    }
}

@media (min-width: 1546px) and (max-width: 1750px){
    .powerplant-wind{
        font-size: 11px;
    }
}
@media (min-width: 1751px){
    .powerplant-wind{
        font-size: 15px;
    }
}
@media (max-width: 1795px){
    .powerplant-wind{
        font-size: 13px;
    }
}

@media (max-width: 1753px){
    .wind-location2 {
        margin-left: 2%;
    }

    .wind-location {
        margin-left: 1%;
    }
}

@media (max-width: 1645px){
    .wind-location2 {
        margin-left: 1%;
    }

    .wind-location {
        margin-left: 0%;
    }
}

@media (max-width: 1523px){
    .wind-location2 {
        margin-left: 0%;
    }

    .wind-location {
        margin-left: -1%;
    }
}

@media (max-width: 1395px){
    .wind-location2 {
        margin-left: -1%;
    }

    .wind-location {
        margin-left: -2%;
    }
}

@media (max-width: 1144px){
    .wind-location2 {
        margin-left: 3%;
    }

    .wind-location {
        margin-left: 3%;
    }
}

@media (max-width: 460px){
    .powerplant-wind {
        margin-top: 2px;
    }
}

@media (max-width: 991px){
    .main-body{
        width: 97%;
    }
    .success-responsive{
        display: none;
    }
    .search-width{
        display: none;
    }
}

@media (max-width: 991px){
    .power-position {
        width: unset;
        padding-bottom: 5px;
    }
    .trackers-on {
        width: 50%;
        position: relative;
    }

    .message-power-position {
        width: 94%;
        max-width: unset;
        z-index: 1;
    }

    .wind-safety-margin {
        margin-top: 5px;
    }

    .sorting-bar-bg{
        display: none;
    }
}

@media (max-width: 1600px){
    table tr:nth-child(n) td:nth-child(n) {
        font-size: 13px;
    }
}

@media (min-width: 1145px) and (max-width: 1330px){
    .location-image-wind-speed {
        margin-left: 4.5%;
    }
}

@media (min-width: 992px) and (max-width: 1200px){
    .col-md-6 {
        width: 100%;
    }
}
html, body {
    background-size: cover;
}

.login-box-msg {
    margin-bottom: 57px;
    text-align: center;
    margin-top: -20px;
}

.success-responsive{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    outline: none; 
    font-weight: normal;
    font-size: 20px;
    line-height: 33px;
    padding: 5px; 
    padding-left: 20px;
    color: #21252B;
    background: #FFFFFF; 
    border: 1px solid #FFFFFF; 
    box-sizing: border-box;
    border-radius: 47px;
    width: 140px;
}

.navbar-items{
    display: flex;
    justify-content: center;
    align-items: center;
}

.titre{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 97px;
    color: #FFFFFF;
}

.input-group-addon:first-child {
    padding-bottom: 5px;
}

.form{
    width: 330px;
    height: 390px;
    background: rgba(0, 135, 177, 0.72);
    border-radius: 49px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.form-prem{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.modal-vertical-centered {
    transform: translate(0, 25%) !important;
    -ms-transform: translate(0, 25%) !important; /* IE 9 */
    -webkit-transform: translate(0, 25%) !important; /* Safari and Chrome */
  }

.Logo-self-align{
    margin-top: 20px;
    margin-bottom: 20px;
}

.b-input{
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 52px;
}

#contact-phone {
    margin-right: 4.5rem;
    margin-left: 13px;
}

.contact-mail{
    margin-right: .5rem;
}

#mentions{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 46px;
    color: #FFFFFF;
    position: absolute;
    padding: 30px;
    bottom: 0;
    left:0;
    margin-left: 500px;  
}

#mentions:hover{
    cursor: pointer;
}

#contact:hover{
    cursor: pointer;
}

.titre-contact{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
     font-style: normal;
     font-weight: normal;
     font-size: 40px;
     color: #0087b1;
     line-height: 80px;
}

#contact{
    position: absolute;
    padding: 30px;
    bottom: 0;
    right:0;
    margin-right: 500px; 
}

.row{
    margin-right: 0;
}

.first-navbar{
    background-color: rgba(52, 52, 52, 0.01);
    border: 0;
    height: 100px;
    margin-top: 10px;
}

.leaflet-top {
    bottom: 0 !important;
    padding-bottom: 15px;
    top: initial;
}

.modal-contact-desktop{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400px;
    font-size: 22px;
    color: #0087b1;
    
    margin-top: 31px;
    margin-bottom: 30px;
    line-height: 1px;
}

.modal-header {
    padding: 6px;
    padding-bottom: 0px;
}

.modal-header .close {
    margin-top: 0px;
    margin-right: 4px;
}

button.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
}

.close {
    float: right;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 1.2;
    outline: none; 
}

.close::after{
    background-image: url('/images/logo/close.svg');
    background-size: 40%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 45px; 
    height: 28px;
    margin-right: -27px;
    content:"";
}

.close:hover{
    color: #FFFFFF;
    opacity: 1.2;
}

.modal-tel-desktop{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px; 
    color: #676767;
    margin-bottom:-7px;
    line-height: 14px;
}

.modal-close-button-desktop{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    width: 88px;
    text-align: center;
    font-style: normal;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
    border-radius: 54px;
    background-color: #0087b1;
    line-height: 30px;
    padding: 2px;
    outline: none;
}

.modal-close-button-desktop:hover{
    cursor: pointer;
    background-color: #0087b1;
}

.modal-credit-desktop{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px; 
    color: #676767;
    line-height: 10px
}


.toogle-wind{
    padding-top: 4px;
}

.toogle-lost-connection{
    padding-top: 9px;
}

@media (max-width: 1350px) {

    #mentions{
        margin-left: 0; 
    }

    #contact{
        margin-right: 0; 
    }
}

@media (max-width: 1200px){
    .map-style{
        position: initial;
        top:initial;
     }
     .powerplant-tracker {
        margin-top: 4px;
        margin-bottom: 4px;
    }
    .wind-location {
        display: flex !important;
        justify-content: space-around;
    }
    .wind-location2 {
        display: flex !important;
        justify-content: space-around;
    }
    .lost-remote-connection-true{
        display: block !important;
    }
    .message-position {
        position: unset;
        width: 52%;
        position: absolute;
    }

    .add-margin{
        margin-top: -1px;
        margin-bottom: 8px;
    }
    .tracker-badge-error {
        margin-right: -19px;
    }
    
}

@media (max-width: 991px){
    .map-style{
        position: initial;
        top:initial;
     }
     .powerplant-tracker {
        margin-top: 4px;
        margin-bottom: 4px;
        justify-content: space-evenly;
    }
    .progress-bar-striped, .progress-striped .progress-bar {
        margin-right: 46vw !important;
        margin-left: 42px;
    }
    .wind-location {
        display: flex !important;
        justify-content: space-around;
    }
    .wind-location2 {
        display: flex !important;
        justify-content: space-around;
    }
    .lost-remote-connection-true{
        display: block !important;
    }
    .message-position {
        position: unset;
        width: 94%;
        position: absolute;
    }

    .add-margin{
        margin-top: -1px;
        margin-bottom: 8px;
    }
    .tracker-badge-error {
        margin-right: -19px;
    }
    
}

@media (max-width:870px) {

    ._ju7HT {
        height: 80%;
    }
    
    ._3QxTi {
        width: 80%;
    }

    
    ._ju7HT._3YMB2 {
        transform: translateX(12px) !important;
    }

    .logo-responsive{
        width: 15px;
        height: 15px;
    }

    .success-responsive{
        width: 6rem;
        height: 3rem;
        font-size: 1rem !important;
        margin-top: -10px;
        margin-left: 1rem;
    }

    .logo-optimum-tracker{
        width: 9rem;
        height: 2rem;
    }

    .Title-Optimum-Tracker{
        font-size: 1.2rem !important;
        padding-top: 1px !important;
    }
    .leaflet-top {
        padding-top: 20px;
        top:0;
        bottom:initial;
    }
    .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY{
        display: none;
    }

    .header-title{
        display: none;
    }

    input#search {
        background-size: contain;
        text-indent: 20px;
        background-position: 11px 1px;
    }

    .toogle-wind{
        padding-top: initial;
    }

    .toogle-lost-connection{
        padding-top: initial;
    }
    
}

 @media (max-width: 767px){
    #contact-phone {
        margin-right: .5rem;
        margin-left: initial;
    }

    .tel-contact-position{
        text-align: center;
    }
 }

@media (max-width: 550px) {

    .titre{
        font-size: 20px;
        line-height: 97px;
        margin-top: -30px;
       
    }

    .logo-tracker{
        margin-top: 5px;
        width: 130px;
        height: 55px;
    }
    
    .form{
        width: 240px;
        height: 330px;
    }
    
    
    .form-prem{
        margin-top: -10px;
    }
    
    .Logo-self-align{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .b-input{
        border: 3px solid #FFFFFF;
        box-sizing: border-box;
    }
    .input-group {
        width: 190px;
    }

    .container{
        width: 150px;
    }
    .login-box-msg {
        font-size: 9px;
    }
}

@media (max-width: 360px){
    .logo-responsive{
        width: 1rem;
        height: 1rem;
    }
}

@media (max-width: 280px){
    .logo-responsive{
        width: 1rem;
        height: 1rem;
    }

    .success-responsive{
        width: 4rem;
        height: 2rem;
        font-size: 0.5rem !important;
        margin-top: -10px;
        margin-left: 1rem;
    }

    .logo-optimum-tracker{
        width: 9rem;
        height: 2rem;
    }

    .title-optimum-tracker {
        font-size: 0.7rem !important;
        padding-top: 1px !important;
        border: 1px ​solid rgb(77, 97, 252) !important;
    }
}


@media (min-width: 768px){
    .modal-sm {
        width: 367px;
    }
}
@media (max-width: 332px){
    .font-sorting-bar-text {
        font-size: 0.7rem;
    }
    .Dropdown-option {
        padding: 0rem 1rem !important;
    }
}

@media (max-height: 750px) {
    #mentions, h4{
        font-size: 12px;
    }

    .contact{
        width: 30px;
        height: 30px;
    }
}

@media (min-width: 1201px) and (max-width: 1283px){
    .logo-responsive{
        width: 1rem;
        height: 1rem;
    }
    .font-sorting-bar-text{
        font-size: 1rem;
    }
    .name {
        font-size: 1rem;
    }
    .message-power-position {
        font-size: 1rem;
        width: 11%;
        max-width: unset;
    }
    .wind-text-logo{
        margin-top: 6px;
    }
    .font-sorting-bar-text {
        line-height: 13px;
    }
}

@media (min-width: 992px) and (max-width: 1200px){
    .logo-responsive{
        width: 2rem;
        height: 2rem;
    }
    .font-sorting-bar-text{
        font-size: 14px;
    }
    .name {
        font-size: 14px;
    }
    .wind-text-logo{
        margin-top: 6px;
    }
    .font-sorting-bar-text {
        line-height: 13px;
    }
}

@media (min-width: 992px) and (max-width: 1200px){
    div.progress {
        /*width: 75%;*/
        /*width: 40%;*/
    }
    .trackers-on {
        width: 20%;
    }

    .message-position {
        position: unset;
        width: 19%;
        position: absolute;
    }

    .message-power-position {
        font-size: 12px;
        width: 15%;
        max-width: unset;
    }
}

@media (min-width: 992px) and (max-width: 1621px){
    .tracker-text-logo{
        padding-left: 0px;
    }
}

@media (min-width: 992px) and (max-width: 1200px){
    .powerplant-tracker {
        margin-top: -1px;
    }
}

@media (max-width: 1388px){
    .another-alignement{
        display: inline-flex;
        padding-bottom: 7px;
    }
}

@media (min-width: 992px) and (max-width: 1170px){
    .col-md-3 {
        width: 10%;
    }
    .power-position {
        margin-left: 24px;
        margin-right: -1px;
        width: 35%;
    }
}

@media (min-width: 650px) and (max-width: 991px){
    .progress-bar-striped, .progress-striped .progress-bar {
        margin-left: 81px;
    }
}

@media (min-width: 2200px) and (max-width: 2500px){
    .message-position {
        width: 18%;
    }
}

@media (min-width: 2501px) and (max-width: 2600px){
    .message-position {
        width: 20%;
    }
}

@media (min-width: 2601px) and (max-width: 3000px){
    .message-position {
        width: 22%;
    }
}

@media (min-width: 3001px) and (max-width: 3299px){
    .message-position {
        width: 23%;
    }
}

@media (min-width: 3300px) and (max-width: 3500px){
    .message-position {
        width: 27%;
    }
}

@media (min-width: 3501px) and (max-width: 3799px){
    .message-position {
        width: 28%;
    }
}

@media (min-width: 3800px) and (max-width: 4000px){
    .message-position {
        width: 29%;
    }
}

@media (min-width: 4001px){
    .message-position {
        width: 32%;
    }
}
/* ---------- */
/* - Layout - */
/* ---------- */
html {
    height: auto;
    min-height: 100%;
}

body {
    position:absolute;
    top:0px;
    bottom:0;
    right:0;
    left:0;
    background-color: rgb(34, 45, 50);
}

.body {
	padding-top: 51px;
}

div.progress {
	margin-bottom: 0px;
}

div.wind {
	padding-right: 0px;
}

/* --------------- */
/* - Spinner tip - */
/* --------------- */

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

*{
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;;
}

