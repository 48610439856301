.leaflet-container {
	height: 100%;
	width: 100%;
	margin: 0 auto;
}

.leaflet-map{
	z-index: 100000;
	border: 8px solid #FFFFFF;
	box-sizing: border-box;
	border-radius: 35px;
	padding: 10px;
}

.leaflet-map-mobile{
	border: 8px solid #FFFFFF;
	box-sizing: border-box;
	border-radius: unset;
	padding: 10px;
}

.toogle-map{
	z-index: 100000; 
	position: relative; 
	display: flex; 
	justify-content: flex-end; 
	align-items: flex-start; 
	padding-top: 15px; 
	padding-right: 15px;
}

.body-style{
	background: #0E202D;
	/*height: 120vh;*/
	padding-bottom: 3%;

}

[href="https://leafletjs.com"]{
	font-size: 11px;
}

[href="https://www.openstreetmap.org/copyright"]{
	font-size: 11px;
	margin-right: 5px;
}

[href="https://www.mapbox.com/"]{
	font-size: 11px;
	margin-right: 40px;
}

.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
    background: rgb(255, 255, 255) none repeat scroll 0% 0% !important;
}

.copyright{
	color: #FFFFFF;
	font-weight: bold;
	font-size: 15px;
	position: relative;
	bottom: 0;
	left: 0;
	padding-left: 50px;
}

.location-image-wind-speed{
	margin-left: 12.5%;
}

.border-sorting{
	padding-left: 0;
}