.search-bar{
    display: flex;
    justify-content: space-between;
}
.search-width{
    width: 160px;
}

.location-image{
    margin-left: 19%;
    /*margin-left: 4%;*/
}

.location-img-logo{
    cursor: pointer;
}

.col-md-9 {
    padding-right: 48px;
    padding-left: 0;
}

.active-menu{
    background-color: #1D7E88;
}

.gx-layout-sider-scrollbar div:first-child{
    overflow-x: hidden !important;
}

#power{
    display: inline-flex;
}

.sorting-dropdown{
    display: none;
}

.style-navbar{
   background-color: rgba(52, 52, 52, 0);
   border:0;
   padding: 0;
   margin: 0;
}

.container-fluid {
    padding-right: unset;
    padding-left: unset;
    margin-right: unset;
    margin-left: unset;
}

.title-optimum-tracker{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style:normal;
    font-weight: normal; 
    font-size: 20px;
    line-height: 33px; 
    padding-top: 7px;
    padding-left: 23px;
    padding-right: 24px;
    background: transparent;
    border: 2px solid #FFFFFF;
    border-radius: 54px;
    color: #FFFFFF;
    margin-right: 15px;
    padding-bottom: 5px;
}

.header-title{
    color: #FFFFFF;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style:normal;
    font-weight: normal; 
}

.header-logo{
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 15px;
}

.logo-optimum-tracker-power{
    margin-right: 10px;
    margin-top: -5px;
    width: 36px;
    height: 36px;
}

.ReactModal__Overlay{
    z-index:-1 !important;
}

.map-style{
   background-color: rgb(14 32 45);
   position: -webkit-sticky;
   position: sticky;
   top:10px;
}

.wind{
    margin-right: .5rem;
}

.wind2-safety{
    margin-right: 0rem;
}

input#search {
    background-image: url('/images/logo/search-noire.png');
    background-size: 21%;
    background-repeat: no-repeat;
    text-indent: 20px;
    background-position: 11px 6px;
}

input#search:focus {
    background-image:none;
    text-indent:0px
}

.logo-filter{
    margin-top: 27px;
    margin-left: -500%;
}

#basic-nav-dropdown{
    margin-top: 12px;
}

#basic-nav-dropdown:before {
    background-image: url('/images/logo/filter-white.png');
    background-size: 104%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 22px; 
    height: 22px;
    content:"";
}


#basic-nav-dropdown-active:before {
    background-image: url('/images/logo/filter-active.png');
    background-size: 104%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 22px; 
    height: 22px;
    content:"";
}

.caret{
     display: none;
}

.Dropdown-root{
    display: none;
}

#basic-nav-dropdown::after{
    background-image: url('/images/logo/btn-dropdown.png');
    background-size: 40%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 22px; 
    height: 13px;
    content:"";
}

#basic-nav-dropdown-active::after{
    background-image: url('/images/logo/filter-active-caret.png');
    background-size: 40%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 22px; 
    height: 13px;
    content:"";
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
    color: #555;
    background-color: transparent;
}

.dropdown-menu .divider {
    background-color: #0087B1;
}

.dropdown-menu {
    border: 1px solid rgb(29, 126, 136);
}

.font-filters-name{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    color: #000000;
}

.font-filters-name:hover{
    text-decoration: none;
    color: #FFFFFF;
}

.dropdown-menu>li>a {
    outline: none;
}

.dropdown-menu>li>a:hover{
    background-color: #0087B1;
}

.dropdown-menu>li>a{
    padding-top: 5px;
    padding-bottom: 5px;
}

.lost-connection{
    margin-left: -1px;
    margin-right: 3px;
}

.navbar-nav > li > .dropdown-menu {
    margin-top: -14px;
}

.dropdown-menu{
    padding: 0px 0;
}

.dropdown-menu .divider {
    margin: 0px 0;  
}

/*sorting-bar central items*/
.sorting-bar{
    display: flex;
    justify-content: space-evenly;
    background: #23527c;
    box-sizing: border-box;
    border-radius: 27px;
    margin-bottom: 5px;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 4%;
    padding-right: 0%;
    width: 99%;
}

.sorting-bar-bg{
    /*background: #23527c;*/
    box-sizing: border-box;
    border-radius: 27px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 100;
    top: 0;
}

.border-sorting-bar{
    border-bottom: unset !important;
}

table thead tr:first-child th:first-child {
    border-bottom-left-radius: 27px;
    border-top-left-radius: 27px;
}

table thead tr:last-child th:last-child {
    border-bottom-right-radius: 27px;
    border-top-right-radius: 27px;
}

table thead tr th {
    background-color: #23527c;
}

table tr:nth-child(n) td:first-child {
    border-bottom-left-radius: 27px;
    border-top-left-radius: 27px;
}

table tr:nth-child(n) td:last-child {
    border-bottom-right-radius: 27px;
    border-top-right-radius: 27px;
}

table tr:nth-child(n) td:nth-child(n) {
    border-top: unset !important;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: #23527c;
     /*display: block;*/
    /*width: 300%;*/
    white-space: nowrap;
    margin-bottom: 10px;
    background: #FFFFFF;
}

.lost-remote-connection-true-bg{
    color: #777 !important;
    cursor: not-allowed;
    background: #eee !important;
    border: 1px solid #eee !important;
}

table {
    border-collapse: separate;  
    /*border-spacing: 0px 9px;*/
    border-spacing: 0px 4px;
}

.table-sorting-name{
    padding-left: 28px !important;
}

/*table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}*/

/*sorting-bar central items*/
.font-sorting-bar-text{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 23px;
    color: #FFFFFF;
}

.font-sorting-bar-text:hover{
    text-decoration: none;
    color: #FFFFFF;
    cursor: pointer;
}

.font-sorting-bar-text-blue{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    font-size: 11px;
    line-height: 15px;
    color: #000000;
    margin-right: 5px;
}

.font-sorting-bar-text-blue:hover{
    text-decoration: none;
    color: #FFFFFF;
    cursor: pointer;
}

.wind-text-logo{
    display: inline-flex;
}

.text-trackers::after{
    background-image: url('/images/logo/boutton-dropdown-white.png');
    background-size: 28%;
    background-repeat: no-repeat;
    display: inline-block;
    width: 22px; 
    height: 5px;
    margin-left: 7px;
    content:"";
}

#dropdown-no-caret{
    background-color: transparent;
    border: 0;
    padding: 0;
    color: #FFFFFF;
    outline: none;
    box-shadow: none;
}

#dropdown-no-caret:hover{
    background-color: transparent;
    border-color: transparent;
    color: #FFFFFF;
}

.dropdown-menu{
    border-radius: 0;
}

.sorting-bar-hover:hover{
    cursor: pointer;
}

[aria-labelledby="dropdown-no-caret"]{
    left: -74px;
}

.navbar-default .navbar-toggle {
    border-color: transparent;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #3e8cc7;
}

.all-item-elements{
    display: inline-flex;
}

.pinwheel{
    /*margin-left: -11%;*/
    margin-left: -30%;
}

@media (min-width: 768px){
    .navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand {
        margin-left: 15px;
    }

}

@media (min-width: 992px){
    .sorting-bar{
        display: flex;
        justify-content: space-around;
    }
}

@media (max-width: 991px){
    .navbar-nav {
        margin: 7.5px -15px;
        display: flex;
        justify-content: center;
    }

    .nav {
        padding-left: 0;
        list-style: none;
    }

    #basic-nav-dropdown{
        margin-top: 0px;
    }

    .navbar-nav .open .dropdown-menu{
        position: unset;
        background-color: #FFFFFF;
        border: 1px solid rgb(29, 126, 136);
        margin-bottom: 14px !important;
        margin-top: -14px;
        margin-left: -56px;
    }
    .dropdown-menu > li > a {
        color: #000000;
        background-color: none;
    }

    #power{
        display: none !important;
    }

    .Dropdown-root{
        display: block;
        position: relative;
        margin-top: 73px;
        text-align: center;
        display: flex;
        justify-content: center; 
    }

    .Dropdown-menu {
        width: 40% !important;
    }
    .Dropdown-arrow {
        border-color: #FFFFFF transparent transparent !important;
    }
    .is-open .Dropdown-arrow {
        border-color: transparent transparent #FFFFFF !important;
    }
    .Dropdown-control {
        background-color: #23527c !important;
        color: #FFFFFF !important;
    }
    .Dropdown-menu {
        background-color: #23527c !important;
        text-align: left !important;
    }
    .Dropdown-option {
        color: #FFFFFF !important;
        padding: 0.4rem 1rem !important;
    }
    .Dropdown-option.is-selected {
        background-color: #23527c !important;
        color: #FFFFFF !important;
    }
    .Dropdown-option:hover {
        background-color: #0087B1 !important;
        color: #333 !important;
    }
    .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
        background-color: transparent !important;
    }
    .sorting-bar{
        display: none;
    }
    .sorting-dropdown{
        display: block;
    }
    .font-sorting-bar-text {
        font-size: 11px;
    }
}

@media (max-width: 460px){
    .central-items{
        flex-direction: column;
    }
}

@media (min-width: 768px) and (max-width: 870px){
    .navbar-nav {
        margin: -0.5px -15px;
    }
}

@media (min-width: 461px) and (max-width: 540px){
    .location-logo-responsive{
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 10%;
    }
    .location-image {
        margin-left: 5%;
    }
    .windsafety-logo-responsive{
        margin-top: 3px;
        width: 1rem;
        height: 1rem;
    }
    .powerplant-wind {
        margin-top: -1px;
    }
    .central-items{
        padding-left: 30px !important;
        padding-right: 5px;
    }
}

@media (max-width: 382px){
    .font-sorting-bar-text {
        font-size: 1rem;
    }
    .Dropdown-option {
        padding: 0.3rem 1rem !important;
    }
}


@media (min-width: 768px) and (max-width: 870px){
    #basic-nav-dropdown {
        margin-top: 0;
    }
    #power {
        margin-top: -9px;
    }
}

@media (min-width: 992px) and (max-width: 1330px){
    .location-logo-responsive{
        width: 22px;
        height: 22px;
        margin-right: 10%;
    }
    .location-image {
        margin-left: 18%;
    }
    .windsafety-logo-responsive{
        margin-top: 3px;
        width: 15px;
        height: 15px;
    }
    .powerplant-wind {
        margin-top: -1px;
        font-size: 5px;
        font-weight: bold;
    }
}

@media (min-width: 1145px) and (max-width: 1283px){
    .powerplant-wind {
        font-size: 7px;
    }
}

@media (min-width: 1284px) and (max-width: 1330px){
    .powerplant-wind {
        font-size: 7px;
    }
}
@media (min-width: 1331px) and (max-width: 1545px){
    .powerplant-wind {
        font-size: 7px;
    }
}

@media (min-width: 1546px) and (max-width: 1750px){
    .powerplant-wind{
        font-size: 11px;
    }
}
@media (min-width: 1751px){
    .powerplant-wind{
        font-size: 15px;
    }
}
@media (max-width: 1795px){
    .powerplant-wind{
        font-size: 13px;
    }
}

@media (max-width: 1753px){
    .wind-location2 {
        margin-left: 2%;
    }

    .wind-location {
        margin-left: 1%;
    }
}

@media (max-width: 1645px){
    .wind-location2 {
        margin-left: 1%;
    }

    .wind-location {
        margin-left: 0%;
    }
}

@media (max-width: 1523px){
    .wind-location2 {
        margin-left: 0%;
    }

    .wind-location {
        margin-left: -1%;
    }
}

@media (max-width: 1395px){
    .wind-location2 {
        margin-left: -1%;
    }

    .wind-location {
        margin-left: -2%;
    }
}

@media (max-width: 1144px){
    .wind-location2 {
        margin-left: 3%;
    }

    .wind-location {
        margin-left: 3%;
    }
}

@media (max-width: 460px){
    .powerplant-wind {
        margin-top: 2px;
    }
}

@media (max-width: 991px){
    .main-body{
        width: 97%;
    }
    .success-responsive{
        display: none;
    }
    .search-width{
        display: none;
    }
}

@media (max-width: 991px){
    .power-position {
        width: unset;
        padding-bottom: 5px;
    }
    .trackers-on {
        width: 50%;
        position: relative;
    }

    .message-power-position {
        width: 94%;
        max-width: unset;
        z-index: 1;
    }

    .wind-safety-margin {
        margin-top: 5px;
    }

    .sorting-bar-bg{
        display: none;
    }
}

@media (max-width: 1600px){
    table tr:nth-child(n) td:nth-child(n) {
        font-size: 13px;
    }
}

@media (min-width: 1145px) and (max-width: 1330px){
    .location-image-wind-speed {
        margin-left: 4.5%;
    }
}

@media (min-width: 992px) and (max-width: 1200px){
    .col-md-6 {
        width: 100%;
    }
}