.name{
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
	/*display: ruby;*/
    line-height: 20px;
    color: #23527c;
	/*display: block;*/
    line-height: 20px;
    color: #23527c;
    /*width: 300%;*/
	padding-left: 20px;
	white-space: nowrap;
}

.name:hover{
	color: #23527c;
}

.name-padding{
	padding-left: 15px;
}

.powerplant-name{
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
	/*display: ruby;*/
    line-height: 20px;
    color: #23527c;
	display: block;
    line-height: 20px;
    color: #23527c;
    width: 300%;
	padding: 0;
	padding-right: -5px;
	margin: 0;
}

.powerplant-name:hover{
	color: #23527c;
}

.location{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: -10px;
    padding-right: 15px;
    padding-bottom: 15px;
}


.marker-btn {
	background: none;
	border: none;
	cursor: pointer;
}
  
.marker-btn img {
	width: 20px;
	height: 20px;
}

.powerplant-power{
	text-align: center; 
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif; 
	font-style: normal; 
	font-weight: normal; 
	font-size: 17px;
	color: #000000;
	line-height: 20px;
}

.powerplant-wind{
	vertical-align: top; 
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif; 
	font-style: normal; 
	font-weight: normal; 
	font-size: 11px;
	color: #23527c; 
	line-height: 20px;
	white-space: nowrap;
	/*padding-left: 0px;*/
}

.powerplant-tracker{
	text-align: center; 
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif; 
	font-style: normal; 
	font-weight: normal; 
	font-size: 15px;
	color: #000000;
	line-height: 20px;
	display: flex;
    justify-content: space-between;
}

.progress-bar {
    color: #333;
}

.wind-location{
	display: inline-flex;
	margin-left: 1%;
}

.wind-location2{
	display: inline-flex;
	/*margin-left: 20px;*/
	margin-left: 2.4%;
}

.central-items{
	display: flex;
	justify-content: space-evenly;

}

.power-progress-bar{
	font-size: 12px;
	/*padding-left: 5px;*/
	/*background: #4D61FC;*/
	color: transparent;
	background: #0087b1;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 25px;
}

.no-power-progress-bar{
	color: transparent;
	border-radius: 25px;
	background:linear-gradient(0deg, #B2B6BB, #B2B6BB), linear-gradient(0deg, #B2B6BB, #B2B6BB), #B2B6BB;
}

.tracker-progress-bar{
	background-color: #66C872;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 25px;;
	color: #FFFFFF;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;;
	font-size: 12px;
	height: 21px;
}

.another-tracker-progess-bar{
	background-color: #66C872;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 25px;;
	color: #FFFFFF;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;;
	font-size: 12px;
	height: 21px;
	width: 100 !important;
}

.tracker-badge-manual{
	color: #FFFFFF;
	background: #FDB62F;
	font-size: 15px;
	border-radius: 10px;
	display: inline-block;
	/*font-weight: 700;*/
	line-height: 1;
	min-width: 10px;
	padding: 3px 7px;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
	/*margin-left: -2px;*/
	margin-left: 8px;
	margin-top: -1px;
}

.remove-margin{
	margin-top: -3px;
}

.tracker-badge-error{
	color: #FFFFFF;
	background: #E01818;
	font-size: 15px;
	border-radius: 10px;
	display: inline-block;
	/*font-weight: 700;*/
	line-height: 1;
	min-width: 10px;
	padding: 3px 7px;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
	margin-left: 7px;
	/*margin-right: 17/12px;*/
	/*margin-right: -19px;*/
	margin-top: -1px;
}

.lost-remote-connection-true{
	color: #777;
	cursor: not-allowed;
	background: #eee;
	border: 1px solid #eee;
	box-sizing: border-box;
	border-radius: 27px;
	padding-top: 11px; 
	padding-bottom: 6px; 
	padding-left: 2%;
	width: 99%; 
	margin-left: 0%;
	display: flex !important;
	margin-bottom: 4px;
}

.not-message{
	display: none;
}

.message-position{
	font-size: 15px;
	font-weight: bold;
	color: #FFFFFF;
	position: absolute;
	/*width: 20%;*/
	width: 16%;
	z-index: 1;
}

.message-power-position{
	font-size: 14px;
	color: #FFFFFF;
	position: absolute;
	/*width: 88%;*/
	width: 13.5%;
	z-index: 1;
}

.lost-remote-connection-false{
	color: #777;
	background: #FFFFFF;
	border: 1px solid #23527c;
	box-sizing: border-box;
	border-radius: 27px;
	padding-top: 11px; 
	padding-bottom: 4px; 
	padding-left: 2%; 
	width: 99%; 
	margin-left: 0%; 
	margin-bottom: 4px;
}

.power-position{
	margin-left: 24px;
    margin-right: -1px;
	width: 21%;
}

.powerplant-style-screen-mode{
	display: block;
}

.lost-remote-conn-margin{
	margin-left: 5px;
}

.wind-safety-margin{
	margin-top: -1px;
}

@media (min-width: 1201px) and (max-width: 1376px){
	.tracker-badge-manual{
		font-size: 10px;
	}

	.tracker-badge-error{
		font-size: 10px;
	}

	.message-position{
		font-size: 12px;
		width: 11%;
    	margin-top: 2px;
	}

	.message-power-position {
		font-size: 12px;
	}

	.name {
		font-size: 13px;
	}

	table tr:nth-child(n) td:nth-child(n) {
		font-size: 13px;
	}
}


@media (min-width: 1376px) and (max-width: 1425px){
	.message-position {
		font-size: 12px;
		width: 12%;
   		margin-top: 2px;
	}
	.tracker-badge-error{
		font-weight: initial;
	}
	.tracker-badge-manual{
		font-weight: initial;
	}
}

@media (min-width: 1426px) and (max-width: 1600px){
	.message-position {
		font-size: 12px;
		width: 13%;
   		margin-top: 2px;
	}
	.tracker-badge-error{
		font-weight: initial;
	}
	.tracker-badge-manual{
		font-weight: initial;
	}
}

@media (min-width: 1601px) and (max-width: 1750px){
	.message-position {
		width: 15%;
	}
}

@media (min-width: 992px) and (max-width: 1200px){
	.tracker-badge-manual{
		font-size: 14px;
	}

	.tracker-badge-error{
		font-size: 14px;
		margin-left: -42px;
	}

	.message-position{
		font-size: 14px;
	}
}

@media (min-width: 1200px) and (max-width: 1600px){
	.logo-connection-responsive{
		width: 1rem;
		height: 1rem;
	}
}

@media (max-width: 991px){
	#popover-zoom-location{
		display: none !important;
	}
	
}